import * as React from "react"

import { Box, Container, HStack, Heading, Link, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from "@chakra-ui/react"
import { PageProps, graphql } from "gatsby"

//Sections
import {Carousels} from '../components/Slider/Slider'
import Disclaimer from '../components/Sections/Disclaimer'
import { HaveQuestions } from "../components/Sections/HaveQuestions"
import Layout from "../components/Base/Layout"
import PopUp  from "../components/PopUp/PopUp"
import SectionPicture from '../components/Sections/IndexSectionTwo'
import { SingleProductCard } from "../components/Product/SingleProductCard"

// markup
const IndexPage: React.FC<IndexProps> = ({ data }) => {


  const {
    FeaturedProduct: { feature },
    CategoryProducts: { category },
    allWpProductTag: {
      nodes: [{
        products: {nodes}
      }]
    },
    Restylane: {
      nodes: [{
        products: {RestylaneProducts}
      }]
    },
     Sculptra: {
      nodes: [{
        products: {SculptraProducts}
      }]
    },
  } = data

  const popUpAlreadyShown = typeof window !== "undefined" && sessionStorage.getItem("popUpAlreadyShown")
  const [showPopUp, setShowPopUp] = React.useState(false)

  React.useEffect(() => {
  if(!popUpAlreadyShown) {  
    setTimeout(() => setShowPopUp(true), 3000);
    sessionStorage.setItem("popUpAlreadyShown", "true")}
  }, [])

  return (
    <Layout>
      {/* SLIDER */}
      <Carousels></Carousels>
      <div style={{zIndex: "999", position: "absolute" }}>
      <PopUp isOpen={showPopUp} setIsOpen={setShowPopUp} /> 
      </div>

      <SectionPicture></SectionPicture>

       <Container as="section" bg="white" px={{ base: '8', lg: '12' }} py={{ base: '8', lg: '12' }} width="100%" maxW="100%">
        <Box  pb="64px">
          <HStack justifyContent="space-between" alignItems='center' pb="16px">
            <Box>
              <Heading fontSize="4xl" color="blue.500"> Best Sellers </Heading>
            </Box>
            <Box>
              <Link href="/shop" fontSize="xl" color="blue.500" > View All </Link>
            </Box>
          </HStack>
          <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
            {feature.map((product:any) => (
              <div key={product.id}>
                <SingleProductCard props={product}></SingleProductCard>
              </div>
            ))}
          </SimpleGrid>
        </Box>
        
         {/* <Box py={{ base: '0', lg: '12' }}  pb="64px">
          <HStack justifyContent="space-between" alignItems='center'  pb="16px">
            <Box>
              <Heading fontSize="4xl" color="blue.500">Orthopedic Injectibles</Heading>
            </Box>
            <Box>
              <Link href="/category/orthopedic-injectables" fontSize="xl" color="blue.500" > View All </Link>
            </Box>
          </HStack>
          <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
            {category.map((product:any) => (
              <div key={product.id}>
                <SingleProductCard props={product}></SingleProductCard>
              </div>
            ))}
          </SimpleGrid>
          </Box> */}

        <Box py={{ base: '0', lg: '12' }}  pb="64px">

          {/* TABS */}
          <Stack spacing="16">
            {['lg'].map((size) => (
              <Tabs key={size} size={size} variant='soft-rounded' colorScheme="blue" align="start">
                <HStack
                  flexDirection={{ base: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems={{ base: "flex-start", md: "center" }}
                  gap={{base: "16px", md: "0px"}}
                  pb="16px"
                >
                  <Heading fontSize="4xl" color="blue.500"> Popular Brands </Heading>
                  <TabList marginInlineStart="0px!important">
                    <Tab _selected={{ color: 'white', bg: 'blue.600' }} fontSize={{base: "12px", md: "18px"}} >Radiesse®</Tab>
                    <Tab _selected={{ color: 'white', bg: 'blue.600' }} fontSize={{base: "12px", md: "18px"}}>Restylane®</Tab>
                    <Tab _selected={{ color: 'white', bg: 'blue.600' }} fontSize={{base: "12px", md: "18px"}}>Sculptra®</Tab>
                  </TabList>
                </HStack>
                <TabPanels>
                  {/* FISRT TAB */}
                <TabPanel>
                  <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                    {nodes.slice(0,4).map((product:any) => (
                      <div key={product.id}>
                        <SingleProductCard props={product}></SingleProductCard>
                      </div>
                    ))}
                  </SimpleGrid>
                  </TabPanel>

                  {/* SECOND TAB */}
                  <TabPanel>
                    <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                    {RestylaneProducts.slice(0,4).map((product:any) => (
                      <div key={product.id}>
                        <SingleProductCard props={product}></SingleProductCard>
                      </div>
                    ))}
                  </SimpleGrid>
                  </TabPanel>

                  {/* THIRD TAB */}
                    <TabPanel>
                      <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                      {SculptraProducts.slice(0,4).map((product:any) => (
                        <div key={product.id}>
                          <SingleProductCard props={product}></SingleProductCard>
                        </div>
                      ))}
                  </SimpleGrid>
                  </TabPanel>
              </TabPanels>
              </Tabs>
          ))}
        </Stack>  
        </Box>
      </Container>
      
      <HaveQuestions></HaveQuestions>
      
      {/* LAST SECTION */}
      <Disclaimer></Disclaimer>
    </Layout>
  )
}

type IndexProps = PageProps & {
  data: {

      FeaturedProduct: {
        feature: Array<{ name?: string; id: string; slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
      },
      CategoryProducts: {
        category: Array<{ name?: string; id: string; slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
      },
       allWpProductTag: {
         nodes: [{
           products: {
            nodes: Array<{ name?: string; id: string; slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
          }
        } ]
      }, 
      Restylane: {
      nodes: [{
        products: {
          RestylaneProducts: Array<{ name?: string; id: string; slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
        }
      } ]
      }, 
      Sculptra: {
         nodes: [{
           products: {
            SculptraProducts: Array<{ name?: string; id: string; slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
          }
        } ]
      }, 

  }
}

export const query = graphql`
 query MyQuery {
  FeaturedProduct: allWpProduct(filter: {featured: {eq: true}}, limit: 4) {
    feature: nodes {
      id
      slug
      name
      databaseId
      image {
        sourceUrl
      }
      productTags {
        nodes {
          name
        }
      }
      ... on WpSimpleProduct {
        id
        price
      }
    }
  }

  CategoryProducts: allWpProduct(
    filter: {productCategories: {nodes: {elemMatch: {id: {eq: "dGVybTo5MjA="}}}}}
    limit: 4
  ) {
    category: nodes {
      id
      name
      slug
      image {
        sourceUrl
      }
      productTags {
        nodes {
          id
          name
        }
      }
      ... on WpSimpleProduct {
        price
      }
    }
  }

  allWpProductTag(filter: {name: {eq: "Radiesse®"}}, limit: 4) {
    nodes {
      products {
        nodes {
          name
          databaseId
          slug
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }

  Restylane: allWpProductTag(filter: {name: {eq: "Restylane®"}}, limit: 4) {
    nodes {
      products {
        RestylaneProducts: nodes {
          name
          slug
          databaseId
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }

   Sculptra: allWpProductTag(filter: {name: {eq: "Sculptra®"}}, limit: 4) {
    nodes {
      products {
        SculptraProducts: nodes {
          name
          slug
          databaseId
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }
}
`

export default IndexPage

