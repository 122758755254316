import * as React from 'react'

import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

import { MdCall } from 'react-icons/md'

export const HaveQuestions = () => (
    <Box
      bg="gray.50"
      px={{ base: '6', lg: '16' }}
    py={{ base: '10', lg: '16' }}
    as="section"
    >
      <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between" alignItems="center">
        <Stack spacing="4">
          <Heading size="lg" color="blue.500">Have a question?</Heading>
          <Text color="gray.600" fontSize="18px">
          Our Customer service is here to clear out any dilemma. Feel free to give us a call!
          </Text>
        </Stack>
        <Stack spacing="3" direction={{ base: 'column', sm: 'row' }} justify={{ base: 'start' }}>
          <Button as="a" href="tel:+1 855 564-1492" leftIcon={<MdCall />} variant="solid" bg="blue.500" color="white" size="lg">
            +1 855 564-1492
          </Button>
        </Stack>
      </Stack>
    </Box>
)