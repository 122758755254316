import React from "react"
import {
  Box,
  Heading,
  Stack,
  Text,
  Container,
} from '@chakra-ui/react'

import { useStaticQuery, graphql } from 'gatsby';

const Disclaimer = () => {

  const data = useStaticQuery(graphql`
        {
        wpPage(slug: {eq: "home"}) {
          disclaimer {
            disclaimer
          }
        }
      }
    `)

  return (
    <Box as="section" bg="blue.500" py='28'>
      <Container maxW="720px">
        <Stack w={{ base: 'auto', lg: '720px' }} alignItems="center" justifyContent="center" margin="auto">
          <Heading color="white"> Disclaimer </Heading>
          <Box color="white" pt="8px" dangerouslySetInnerHTML={{ __html: data.wpPage.disclaimer.disclaimer }}></Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Disclaimer