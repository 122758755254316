import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { graphql, useStaticQuery } from 'gatsby';

const SectionPicture = () => {

    const query = useStaticQuery(graphql`
        {
            wpPage(slug: {eq: "home"}) {
                homePage {
                desc
                title
                imageLeft {
                    sourceUrl
                }
                imageRight {
                    altText
                    sourceUrl
                }
                }
            }
        }
    `)

    const data = query.wpPage.homePage

    return (
    <Box as="section" bg="gray.50" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
        <Stack direction={{ base: 'column-reverse', lg: 'row' }} justify="space-between" spacing={{ base: '0', lg: '20' }}>
            <Box
                width={{base: 'auto', lg: "50%"}}
                transform={{ base: 'translateY(-50%)', lg: 'none' }}
                bg="gray.50"
                mx={{ base: '6', md: '8', lg: '0' }}
                px={{ base: '6', md: '8', lg: '0' }}
                py={{ base: '6', md: '8', lg: '12' }}
            >
                <Stack spacing={{ base: '8', lg: '10' }}>
                    <Stack spacing={{ base: '2', lg: '4' }}>
                        <Heading size="xl" color="blue.500">
                            {data.title}
                        </Heading>
                        <Text fontSize="4xl" fontWeight="normal" dangerouslySetInnerHTML={{ __html: data.desc }}></Text>
                    </Stack>
                    <HStack spacing="3">
                        <Link href="/shop" color="blue.500" fontWeight="bold" fontSize="lg">
                            Shop now
                        </Link>
                        <Icon color="blue.500" as={FaArrowRight} />
                    </HStack>
                </Stack>
            </Box>
            <Flex flex="1" overflow="hidden" maxW="fit-content" margin="0 !important">
                <Image
                    src={data.imageLeft.sourceUrl}
                    alt="Lovely Image"
                    fallback={<Skeleton />}
                    maxH="450px"
                    maxW={{base: 'auto', lg:"300px"}}
                    objectFit="cover"
                    flex="1"
                />
                <Image
                    display={{ base: 'none', sm: 'initial' }}
                    src={data.imageRight.sourceUrl}
                    alt="Lovely Image"
                    fallback={<Skeleton />}
                    maxH="450px"
                    objectFit="cover"
                />
            </Flex>
        </Stack>
        </Box>
    )
}

export default SectionPicture