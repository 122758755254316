import * as React from "react"

import {
    AspectRatio,
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Skeleton,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import { Logo } from "../Logo/Logo"

  const PopUp = ({isOpen, setIsOpen}) => {

    return (
    <Box height="100vh">
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        isCentered
        size="5xl"
        blockScrollOnMount={true}
        trapFocus={false}
      >
     
          <>
          <ModalOverlay />
          <ModalContent borderRadius="none" mx="4">
            <ModalCloseButton size="lg" />
            <ModalBody padding="0">
              <Flex align="center">
                <AspectRatio
                  ratio={3 / 4}
                  width="50%"
                  maxW={{ lg: 'md' }}
                  display={{ base: 'none', md: 'flex' }}
                >
                  <Image
                    src="/images/popup_image.jpg"
                    alt="SphericMed"
                    fallback={<Skeleton />}
                  />
                </AspectRatio>
    
                <Flex direction="column" align="center" flex="1" py="12" px={{ base: '4', md: '6' }}>
                  <Box maxW="xl" mx="auto">
                    <Logo height="10" mx="auto" />
                    <Box textAlign="center" maxW={{ base:'2xs', sm: '5xl' }} mx="auto" mt="10">
                      <Heading fontSize='4xl' fontWeight='extrabold'>Stock up on your favorite products!</Heading>
                      <Text fontSize="lg" mt="4">
                        Buy 10 Botulinum Toxins, get 2 free
                      </Text>
                      <Text fontSize="lg">
                      Buy 10 Dermal Fillers, get 3 free!
                      </Text>
                    </Box>
    
                    <Stack spacing="7" mt="12">
                    <Link href="/shop" _hover={{textDecoration: "none"}} display='block' margin="0px auto !important" ><Button bg="blue.500" variant="solid" size="lg" fontWeight='bold'  minWidth="250px">SHOP NOW</Button></Link> 
                    </Stack>
                  </Box>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
          </>
      </Modal>
    </Box>

    )
}

export default PopUp