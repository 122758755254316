import React from "react"
import { Flex, Box, Text, Stack, HStack, Link, Button } from "@chakra-ui/react"
import { useState } from 'react';

 export function Carousels() {
  const arrowStyles = {
    cursor: "pointer",
    display: {base: "none", md: "flex"}, 
    justifyContent: "center",
    alignItems: "center",
    pos: "absolute",
    top: "50%",
    w: "48px",
    h: "48px",
    mt: "-22px",
    p: "16px",
    color: "teal.500",
    fontWeight: "bold",
    fontSize: "12px",
    border: "1px solid #BAC1CF",
    transition: "0.6s ease",
    borderRadius: "50%",
    userSelect: "none",
    _hover: {
      opacity: 0.8,
      bg: "#BAC1CF",
    },
  };

  const slides = [
    {
      img: "/images/slide-1.jpg",
      label: "Botulinum Toxin",
      description: "Buy 10 & Receive 2 Free",
      href:"/category/botulinum-toxins"
    },
    {
      img: "/images/slide-2.jpg",
      label: "Dermal Fillers",
      description: "Buy 10 & Receive 3 Free",
      href:"/category/dermal-fillers"
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = slides.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };
  const setSlide = (slide: any) => {
    setCurrentSlide(slide);
  };
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };

  return (
    <Flex
      w="full"
      bg="white"
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="full" pos="relative" overflow="hidden">
        <Flex h="544px" w="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none" display="flex" alignItems="center" justifyContent="center">
              <Box
                background={"linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url('" + slide.img + "');"}
                boxSize="full"
                backgroundSize="cover"
                backgroundPosition="center"
              />
              <Stack
                pos="absolute"
                textAlign={{base: "center", md:"left"}}
                w={{base: "90%", md:"520px"}}
                color="white"
                className="slideInfo"
                alignItems="center"
              >
                <Text textAlign={{base: "center", md:"left"}} lineHeight="none" fontSize="5xl" fontWeight="700">{slide.label}</Text>
                <Text fontSize="lg">{slide.description}</Text>
               <Link href={slide.href} _hover={{textDecoration: "none"}} display='block' margin="0px auto !important" ><Button bg="blue.500" variant="solid" size="lg" mt="16px !important" > Shop Now </Button></Link> 
              </Stack>
            </Box>
          ))}
        </Flex>
        <Text {...arrowStyles} left="40px" onClick={prevSlide}>
          &#10094;
        </Text>
        <Text {...arrowStyles} right="40px" onClick={nextSlide}>
          &#10095;
        </Text>
        <HStack justify="center" pos="absolute" bottom="40px" w="full">
          {Array.from({ length: slidesCount }).map((_, slide) => (
            <Box
              key={`dots-${slide}`}
              cursor="pointer"
              boxSize={["7px", , "15px"]}
              marginInlineStart="16px !important"
              bg={currentSlide === slide ? "cyan.500" : "blue.50"}
              rounded="50%"
              display="inline-block"
              transition="background-color 0.6s ease"
              _hover={{ bg: "blackAlpha.800" }}
              onClick={() => setSlide(slide)}
            ></Box>
          ))}
        </HStack>
      </Flex>
    </Flex>
  );
};